@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.inter-regular{
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.inter-medium {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.inter-bold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}


@font-face {
  font-family: 'elatina';
  src: url('../fonts/elatina.otf') format('opentype'),
  /* Add additional font formats if needed */
}

@font-face {
  font-family: 'Axiata Bold';
  src: url('../fonts/axiata_bold.otf') format('opentype');
  /* Add additional font formats if needed */
}

@font-face {
  font-family: 'Axiata Book';
  src: url('../fonts/axiata_book.otf') format('opentype');
  /* Add additional font formats if needed */
}

@font-face {
  font-family: 'Axiata Medium';
  src: url('../fonts/axiata_medium.otf') format('opentype');
  /* Add additional font formats if needed */
}

.custom-font {
  // font-family: 'elatina', sans-serif;
}

.headings-font {
  font-family: 'Axiata Bold', sans-serif;
}

.captions-font  {
  font-family: 'Axiata Book';
}

.captions-medium  {
  font-family: 'Axiata Medium';
}

#root{
  height: 100vh;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.styled-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
  z-index: 10;
}

.styled-scroll::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}

.styled-scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #E2E8F0;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.hidescrl::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescrl {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

:root{
  --toastify-color-success: #D1FAE5;
  --toastify-text-color-success: #334155;
  --toastify-text-color-error: #334155;
  --toastify-color-error: #FED7D7;
  --toastify-icon-color-success: #10B981 !important;
  --toastify-icon-color-error: #E53E3E !important;
}

.Toastify__toast--error{
  div{
    div{
      svg{
        color: red;
      }
    }
  }
}

.Toastify__toast-icon{
  color: #10B981;
}
.Toastify__close-button{
color: #334155;
}

.Toastify__toast-container {
font-size: 15px;
}

.css-1k62wd2-indicatorContainer{
  color: #212121 !important;
}

.slick-next:before{
  font-size: 30px !important;
  color: #212121 !important
}

.slick-prev:before{
  font-size: 30px !important;
  color: #212121 !important
}

.slick-track{
  margin-left: 5px !important
}